import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { createTranslateLoader } from './translation-loader';
import localeDe from '@angular/common/locales/de';
import { localeIdFactory } from './locale-id-factory';
import { Store } from '@ngrx/store';
import { filter, map, skip, switchMap } from 'rxjs/operators';
import { Tenant } from '../declarations/tenant.interface';
import { getCurrentTenant } from '../states/tenant-state/tenant.selectors';
import dayjs from 'dayjs';

/**
 *
 * This module should be imported only to CoreModule!
 * If you need to use translate pipe, just import the SharedModule or TranslationModule
 */
@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, Store]
      }
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [TranslateService]
    }
  ],
  exports: [TranslateModule]
})
export class TranslationCoreModule {
  constructor(
    private translate: TranslateService,
    private store: Store,
    private http: HttpClient,
    @Optional() @SkipSelf() otherTranslationModule: TranslationCoreModule
  ) {
    if (otherTranslationModule) {
      const err =
        'Translation module imported twice! Dont import TranslationModule to your feature module directly. \
        See translation.module.ts top section comment for more info';
      // eslint-disable-next-line no-console
      console.error(err);
      throw new Error(err);
    }

    registerLocaleData(localeDe);
    this.translate.setDefaultLang('de');
    this.translate.use('de');
    // used for ion2-calendar
    dayjs.locale('de');

    // make sure that we load tenant-specific translations on tenant change
    this.store
      .select(getCurrentTenant)
      .pipe(
        filter(t => t !== 'loading'),
        map(t => t as Tenant),
        // the translation loader waits for first tenant to setup and then load
        // it's translations. Here we care just about other changes to tenants
        skip(1),
        filter(t => !!t?.tenant_setting?.translations_de),
        switchMap(t => this.http.get(t.tenant_setting.translations_de))
      )
      .subscribe(translations => {
        this.translate.setTranslation(
          this.translate.currentLang,
          translations,
          true
        );
      });
  }
}
