import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducers';
import { UserFilterState } from './user.state';

const getFeatureState = createFeatureSelector<UserState>('userState');

export const getCurrentUser = createSelector(
  getFeatureState,
  (state: UserState) => state.currentUser
);

const getUsersFilterFeatureState =
  createFeatureSelector<UserFilterState>('usersFilterState');

export const getUsersFilter = createSelector(
  getUsersFilterFeatureState,
  (state: UserFilterState) => state.usersFilterState
);
