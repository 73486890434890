import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsfeedState } from './newsfeed.state';
import { getCurrentTenantSettings } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantSetting } from '@flink-legacy/interfaces/tenant-setting';

const getFeatureState = createFeatureSelector<NewsfeedState>('newsfeedState');

export const getCurrentNewsfeedFilters = createSelector(
  getFeatureState,
  (state: NewsfeedState) => state.currentNewsfeedFilters
);

export const isNewsfeedFilterActive = createSelector(
  getFeatureState,
  getCurrentTenantSettings,
  (state: NewsfeedState, tenantSettings: TenantSetting) => {
    const filters = state.currentNewsfeedFilters;
    const initialOrigin = tenantSettings.filter_by_location_by_default
      ? 'my_settlement'
      : 'all';
    return (
      filters.filterByGroup.length !== 0 ||
      filters.filterByType.length !== 4 ||
      filters.origin !== initialOrigin
    );
  }
);
