import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@bling-fe/shared/utils';

interface Tenant {
  tenant_setting: {
    cookiebot_id?: string;
    support_email?: string;
  };
}

interface TenantState {
  currentTenant: Tenant | 'loading' | null;
}

type CookiebotConsents = {
  marketing: boolean;
  statistics: boolean;
  preferences: boolean;
  necessary: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class CookiebotService {
  private window: any = window;
  private supportEmail?: string;
  consents$ = new Subject<CookiebotConsents>();

  constructor(
    private store: Store<{ tenantState: TenantState }>,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {
    this.setupConsentHandlers();
  }

  initializeCookiebot() {
    this.store
      .select('tenantState')
      .pipe(
        map(state => state.currentTenant),
        filter(
          (tenant): tenant is Tenant => tenant !== 'loading' && tenant !== null
        ),
        take(1)
      )
      .subscribe(tenant => {
        const cookiebotId = tenant.tenant_setting.cookiebot_id;
        this.supportEmail = tenant.tenant_setting.support_email;

        if (cookiebotId) {
          const script = document.createElement('script');
          script.id = 'Cookiebot';
          script.src = 'https://consent.cookiebot.com/uc.js';
          script.setAttribute('data-cbid', cookiebotId);
          script.async = true;
          script.type = 'text/javascript';

          const placeholder = document.getElementById('CookiebotPlaceholder');
          if (placeholder && placeholder.parentNode) {
            placeholder.parentNode.replaceChild(script, placeholder);
          } else {
            this.showErrorToast();
          }
        }
      });
  }

  private async showErrorToast() {
    const message = this.supportEmail
      ? this.translateService.instant(
          _('COMMON.ERRORS.COOKIEBOT_LOAD_ERROR_WITH_EMAIL'),
          { email: this.supportEmail }
        )
      : this.translateService.instant(
          _('COMMON.ERRORS.COOKIEBOT_LOAD_ERROR_NO_EMAIL')
        );

    const toast = await this.toastController.create({
      message,
      color: 'danger',
      duration: 4000,
      htmlAttributes: { 'data-cy': 'notification-toast' }
    });
    toast.present();
  }

  isCookiebotLoaded(): boolean {
    return !!this.window.Cookiebot?.show;
  }

  show() {
    document.body.classList.add('cookiebot-overlay');
    if (this.isCookiebotLoaded()) {
      this.window.Cookiebot.show();
    } else {
      this.hide();
    }
  }

  hide() {
    document.body.classList.remove('cookiebot-overlay');
    if (
      this.window.Cookiebot &&
      document.getElementById('CybotCookiebotDialog')
    ) {
      this.window.Cookiebot.hide();
    }
  }

  private setupConsentHandlers() {
    ['CookiebotOnAccept', 'CookiebotOnDecline'].forEach(event => {
      window.addEventListener(event, () => {
        this.hide();
        this.consents$.next({
          marketing: this.marketingConsent,
          statistics: this.statisticsConsent,
          preferences: this.preferencesConsent,
          necessary: this.necessaryConsent
        });
      });
    });
  }

  get marketingConsent() {
    return this.window.Cookiebot?.consent?.marketing;
  }
  get statisticsConsent() {
    return this.window.Cookiebot?.consent?.statistics;
  }
  get preferencesConsent() {
    return this.window.Cookiebot?.consent?.preferences;
  }
  get necessaryConsent() {
    return this.window.Cookiebot?.consent?.necessary;
  }
}
