import { environment } from '@bling-fe/shared/env';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getCurrentUser } from '../user-state/user.selectors';

import { TenantState } from './tenant.state';

const getFeatureState = createFeatureSelector<TenantState>('tenantState');

export const getCurrentTenant = createSelector(
  getFeatureState,
  (state: TenantState) => state.currentTenant
);

export const getCurrentTenantApiUrl = createSelector(
  getFeatureState,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return `${state.currentTenant.base_url}/api/v1`;
    } else {
      return `${environment.apiDomain}`;
    }
  }
);

export const getRepairMessagesEnabled = createSelector(
  getFeatureState,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return state?.currentTenant?.tenant_setting?.repair_messages_enabled;
    } else {
      return false;
    }
  }
);

export const getRepairMessagesCancelEnabled = createSelector(
  getFeatureState,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return state.currentTenant.tenant_setting.repair_messages_cancel_enabled;
    } else {
      return false;
    }
  }
);

export const getRepairMessagesRequireCancellationReason = createSelector(
  getFeatureState,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return state.currentTenant.tenant_setting
        .repair_messages_require_cancellation_reason;
    } else {
      return false;
    }
  }
);

export const getRepairMessagesMessagingEnabled = createSelector(
  getFeatureState,
  getCurrentUser,
  (state: TenantState, currentUser) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return (
        state.currentTenant.tenant_setting.service_communication_enabled &&
        !(
          state.currentTenant.tenant_setting.curo_enabled &&
          currentUser.settlement.crm_type === 'curo'
        )
      );
    } else {
      return false;
    }
  }
);

export const getInstantMessagingEnabled = createSelector(
  getFeatureState,
  getCurrentUser,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return state.currentTenant.tenant_setting.instant_messaging_enabled;
    } else {
      return false;
    }
  }
);

export const getDailyReservationsCancelEnabled = createSelector(
  getFeatureState,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return state.currentTenant.tenant_setting
        .daily_reservations_cancel_enabled;
    } else {
      return false;
    }
  }
);

export const getIhzPredefinedTicketCategoriesIds = createSelector(
  getFeatureState,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return state.currentTenant.tenant_setting
        .ihz_predefined_ticket_categories_ids;
    } else {
      return false;
    }
  }
);

export const getCurrentTenantSettings = createSelector(
  getCurrentTenant,
  currentTenant => {
    if (currentTenant !== 'loading' && currentTenant !== null) {
      return currentTenant.tenant_setting;
    }
    return null;
  }
);

export const getCookiebotId = createSelector(
  getFeatureState,
  (state: TenantState) => {
    if (state.currentTenant !== 'loading' && state.currentTenant !== null) {
      return state.currentTenant.tenant_setting.cookiebot_id;
    }
    return null;
  }
);

export const getCurrentUserWithTenantSettings = createSelector(
  getCurrentUser,
  getCurrentTenantSettings,
  (currentUser, tenantSettings) => {
    return { currentUser, tenantSettings };
  }
);
