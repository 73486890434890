import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ActionSheetOptions, AlertOptions } from '@ionic/core';
import { InputAbstract } from '../input.abstract';
import { ModalService } from '@bling-fe/shared/modals/base-modal';
import { SelectPostScopeModalComponent } from '@flink-legacy/modals/select-post-scope-modal/select-post-scope-modal.component';
import { SelectTenantModalComponent } from '@flink-legacy/modals/select-tenant-modal/select-tenant-modal.component';
import { SelectUserModalComponent } from '@flink-legacy/modals/select-user-modal/select-user-modal.component';
import { SelectDocumentCategoryModalComponent } from '@flink-legacy/modals/select-document-category-modal/select-document-category-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { NgFor, NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SelectOption<T = any> {
  label: string;
  image?: string;
  icon?: string;
  value: T;
}

type SelectDocumentCategoryModalOptions = {
  type: 'SELECT_DOCUMENT_CATEGORY';
  groupId: string;
};

type ModalOptions =
  | { type: 'SELECT_POST_SCOPE' | 'SELECT_TENANT' | 'SELECT_USER' }
  | SelectDocumentCategoryModalOptions;

@Component({
  selector: 'fl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true
    }
  ],
  standalone: true,
  imports: [
    TranslateModule,
    IonicModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    SvgIconComponent
  ]
})
export class SelectComponent<T> extends InputAbstract implements OnInit {
  @Input() placeholder: string;
  @Input() options: SelectOption<T>[];
  @Input() required: boolean;
  @Input() interface: 'action-sheet' | 'alert' | 'popover' | 'modal';
  @Input() inputInterface: 'normal' | 'withImage' = 'normal';
  @Input() interfaceOptions: AlertOptions | ActionSheetOptions | ModalOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() optionsChanged = new EventEmitter<any>();

  constructor(
    protected override injector: Injector,
    private modalService: ModalService
  ) {
    super(injector);
  }

  public async onClick(e: Event) {
    if (this.interface === 'modal') {
      e.preventDefault();
      e.stopImmediatePropagation();
      const options = this.interfaceOptions as ModalOptions;

      const selectedOption = await this.selectValueInModal(options);

      if (selectedOption) {
        this.options = [selectedOption as SelectOption<T>];
        this.optionsChanged.emit(selectedOption);
      }
      this.control.patchValue(selectedOption?.value);
    }
  }

  public get selectedOption(): SelectOption<T> {
    return this.options?.find(o => o.value === this.control.value);
  }

  private async selectValueInModal(options: ModalOptions) {
    switch (options.type) {
      case 'SELECT_POST_SCOPE':
        return await this.modalService.presentAndGetResult(
          SelectPostScopeModalComponent,
          {
            value: this.control.value
          }
        );
      case 'SELECT_TENANT':
        return await this.modalService.presentAndGetResult(
          SelectTenantModalComponent,
          {
            value: this.control.value
          }
        );
      case 'SELECT_USER':
        return await this.modalService.presentAndGetResult(
          SelectUserModalComponent,
          {
            value: this.control.value
          }
        );
      case 'SELECT_DOCUMENT_CATEGORY':
        return await this.modalService.presentAndGetResult(
          SelectDocumentCategoryModalComponent,
          {
            value: this.control.value,
            groupId: options.groupId
          }
        );
    }
  }
}
