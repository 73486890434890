import { Injectable } from '@angular/core';
import {
  ActionCableChannel,
  ActionCableChannelType
} from '@flink-legacy/core/declarations/action_cable.interface';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';
import { environment } from '@bling-fe/shared/env';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Consumer, createConsumer, Subscription } from '@rails/actioncable';
import { from, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, map, retry, share, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { marker as _ } from '@bling-fe/shared/utils';
import { TranslateService } from '@ngx-translate/core';

export const connected = Symbol();
export type Connected = typeof connected;

@Injectable({
  providedIn: 'root'
})
export class ActionCableRepository {
  private actionCableSubscriptions = new Map<string, Subscription>();

  constructor(
    private authenticationService: AuthenticationService,
    private tenantStore: Store<TenantState>,
    private toastController: ToastController,
    private translate: TranslateService
  ) {}

  public subscribeToChannel<T extends ActionCableChannel>(
    channel: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any
  ): Observable<Connected | ActionCableChannelType<T>> {
    return this.getWsConsumer().pipe(
      switchMap(consumer => {
        return this.channelObservable<T>(channel, params, consumer);
      })
    );
  }

  public sendMessage<T extends ActionCableChannel>(
    channel: T,
    params: any,
    message: object
  ): void {
    const subscription = this.actionCableSubscriptions.get(
      channel + JSON.stringify(params)
    );
    if (!subscription) {
      throw 'Subscription not found, intialize subscription first';
    }
    subscription.send(message);
  }

  /**
   * Use in pipe on observable from `subscribeToChannel` method. E.g.:
   * this.acr.subscribeToChannel(...).pipe(this.acr.defaltWsConnectionErrorHandler()).subscribe(...)
   */
  public defaultWsConnectionErrorHandler<T>(): MonoTypeOperatorFunction<T> {
    return retry({
      // retries to subscribe every time the returned observable emits => on every retry button click
      delay: () => {
        return new Observable(s => {
          this.toastController
            .create({
              message: this.translate.instant(
                _('COMMON.ERRORS.WS_CONNECTION.ERROR')
              ),
              color: 'dark',
              duration: 0,
              position: 'bottom',
              cssClass: 'toast--connection-error',
              buttons: [
                {
                  text: this.translate.instant(
                    _('COMMON.ERRORS.WS_CONNECTION.RETRY')
                  ),
                  // cssClass: 'ion-button-primary', //TODO: use shadow dom
                  handler: () => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    s.next();
                  }
                }
              ]
            })
            .then(toast => toast.present())
            .catch(e => s.error(e));
        });
      }
    });
  }

  private channelObservable<T extends ActionCableChannel>(
    channel: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any,
    consumer: Consumer
  ): Observable<Connected | ActionCableChannelType<T>> {
    return new Observable(s => {
      let acSubscription = this.actionCableSubscriptions.get(
        channel + JSON.stringify(params)
      );
      acSubscription ||= consumer.subscriptions.create(
        { channel, ...params },
        {
          connected: () => s.next(connected),
          disconnected: () => s.error(`Websocket ${channel} disconnected`),
          messages_list: data => s.next(data),
          received: data => s.next(data),
          rejected: () => s.error(`Websocket ${channel} rejected`)
        }
      );
      this.actionCableSubscriptions.set(
        channel + JSON.stringify(params),
        acSubscription
      );

      // unsubscribe from the websocket channel on observable unsubscribe
      return () => {
        acSubscription.unsubscribe();
        this.actionCableSubscriptions.delete(channel + JSON.stringify(params));
      };
    });
  }

  private getWsConsumer(): Observable<Consumer> {
    return this.tenantStore.select(getCurrentTenant).pipe(
      filter(x => x !== 'loading' && x !== null),
      // get auth data for initialising ws connection
      switchMap((tenant: Tenant) =>
        from(this.authenticationService.getAuthData()).pipe(
          map(authData => ({
            access_token: authData.accessToken,
            uid: authData.uid,
            client: authData.client,
            tenant: tenant.subdomain
          }))
        )
      ),
      // get connected ws consumer
      switchMap(wsConnectionParams => {
        const wsConnectionParamsString = new URLSearchParams(
          wsConnectionParams
        ).toString();

        return new Observable<Consumer>(s => {
          const consumer = createConsumer(
            `${environment.websocketUrl}?${wsConnectionParamsString}`
          );
          consumer.connect();
          s.next(consumer);
          // disconnect consumer on last unsubscribe
          return () => consumer.disconnect();
        });
      }),
      // one consumer can be shared across multiple channels
      // this tracks #of subscribed channels. If we unsubscribe from all channels, consumer.disconnect() is called
      share()
    );
  }
}
