import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GuardsModule } from '@flink-legacy/shared/guards/guards.module';
import { TokenInterceptor } from '@flink-legacy/shared/token.interceptor';
import { ServicesModule } from '@flink-legacy/shared/services/services.module';
import { CoreModule } from '@flink-legacy/core/core.module';
import { SharedModule } from '@flink-legacy/shared/shared.module';
import { FlinkIconsModule } from '@bling-fe/shared/icons/flink-icons.module';
import { TraceService } from '@sentry/angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
      swipeBackEnabled: false
    }),
    AppRoutingModule,
    GuardsModule,
    ServicesModule,
    CoreModule,
    SharedModule,
    FlinkIconsModule,
    IonicModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
