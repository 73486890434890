import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventsFiltersState } from './events.state';
import { INITIAL_EVENT_FILTERS } from '@flink-legacy/core/declarations/article.interface';
import { getCurrentTenantSettings } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantSetting } from '@flink-legacy/interfaces/tenant-setting';

const getFeatureState =
  createFeatureSelector<EventsFiltersState>('eventsFilterState');

export const getCurrentEventsFilters = createSelector(
  getFeatureState,
  (state: EventsFiltersState) => state?.currentEventsFilters
);

export const isEventsFilterActive = createSelector(
  getFeatureState,
  getCurrentTenantSettings,
  (state: EventsFiltersState, tenantSettings: TenantSetting): boolean => {
    const filters = state.currentEventsFilters;

    const initialOrigin = tenantSettings.filter_by_location_by_default
      ? 'my_settlement'
      : 'all';

    return !!(
      filters.period != INITIAL_EVENT_FILTERS.period ||
      filters.memberOnly != INITIAL_EVENT_FILTERS.memberOnly ||
      (filters.groupIds && filters.groupIds.length > 0) ||
      (filters.origin && filters.origin !== initialOrigin) ||
      filters.periodStart ||
      filters.periodEnd
    );
  }
);
