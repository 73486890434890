import { ArticleTypeFilterValue } from './article.interface';

export interface FilterMenuItem {
  icon: string;
  label: string;
  value: ArticleTypeFilterValue;
  isChecked?: boolean;
}

export interface NewsfeedFilters {
  filterByGroup: number[];
  filterByType: ArticleTypeFilterValue[];
  origin: 'my_settlement' | 'all';
}

export const INITIAL_NEWSFEED_FILTERS: NewsfeedFilters = {
  filterByGroup: [],
  filterByType: [
    ArticleTypeFilterValue.ARTICLE,
    ArticleTypeFilterValue.EVENT,
    ArticleTypeFilterValue.POLL,
    ArticleTypeFilterValue.MARKETPLACE
  ],
  origin: 'all'
};
