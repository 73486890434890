import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { from, of, switchMap } from 'rxjs';
import {
  LOAD_CURRENT_NEWSFEED_FILTERS,
  RESET_CURRENT_NEWSFEED_FILTERS,
  SET_CURRENT_NEWSFEED_FILTERS
} from './newsfeed.actions';
import { getCurrentNewsfeedFilters } from './newsfeed.selectors';
import { Preferences } from '@capacitor/preferences';
import { INITIAL_NEWSFEED_FILTERS } from '@flink-legacy/core/declarations/filter.interface';
import {
  SET_CURRENT_TENANT,
  SetCurrentTenantAction
} from '@flink-legacy/core/states/tenant-state/tenant.actions';
import { getCurrentTenantSettings } from '@flink-legacy/core/states/tenant-state/tenant.selectors';

@Injectable()
export class NewsfeedEffects {
  initializeEventsAfterTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_CURRENT_TENANT),
      map(({ payload }: SetCurrentTenantAction) => ({
        type: SET_CURRENT_NEWSFEED_FILTERS,
        payload: {
          ...INITIAL_NEWSFEED_FILTERS,
          origin: payload.tenant_setting?.filter_by_location_by_default
            ? 'my_settlement'
            : 'all'
        }
      }))
    )
  );

  resetNewsfeedFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RESET_CURRENT_NEWSFEED_FILTERS),
      concatLatestFrom(() => this.store.select(getCurrentTenantSettings)),
      map(([, tenantSettings]) => ({
        type: SET_CURRENT_NEWSFEED_FILTERS,
        payload: {
          ...INITIAL_NEWSFEED_FILTERS,
          origin: tenantSettings?.filter_by_location_by_default
            ? 'my_settlement'
            : 'all'
        }
      }))
    )
  );

  loadCurrentNewsfeedFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CURRENT_NEWSFEED_FILTERS),
      mergeMap(() =>
        from(
          Preferences.get({ key: STORAGE_KEYS.CURRENT_NEWSFEED_FILTERS })
        ).pipe(
          map(v => JSON.parse(v.value)),
          map(t => ({
            type: SET_CURRENT_NEWSFEED_FILTERS,
            payload: t ?? INITIAL_NEWSFEED_FILTERS
          }))
        )
      )
    )
  );

  saveCurrentNewsfeedFiltersToStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_CURRENT_NEWSFEED_FILTERS),
        concatLatestFrom(() => this.store.select(getCurrentNewsfeedFilters)),
        switchMap(([, f]) => {
          if (f !== null) {
            return from(
              Preferences.set({
                key: STORAGE_KEYS.CURRENT_NEWSFEED_FILTERS,
                value: JSON.stringify(f)
              })
            );
          } else {
            return of(f);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store
  ) {}
}
