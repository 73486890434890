import { Injectable } from '@angular/core';
import { PaginatedAbstractService } from './paginated.abstract.service';
import {
  UserRepository,
  UserRepositoryParams
} from '../repositories/user.repository';
import { Store } from '@ngrx/store';
import { getCurrentTenantSettings } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { firstValueFrom, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends PaginatedAbstractService<UserRepositoryParams> {
  $defaultOrigin$: Observable<'my_settlement' | 'all'> = this.store
    .select(getCurrentTenantSettings)
    .pipe(
      map(settings =>
        settings.filter_by_location_by_default ? 'my_settlement' : 'all'
      )
    );

  constructor(
    userRepository: UserRepository,
    private store: Store
  ) {
    super(userRepository);
  }

  override async fetch(params?: UserRepositoryParams['queryParams']) {
    const origin = await firstValueFrom(this.$defaultOrigin$);
    super.fetch({
      ...params,
      origin
    });
  }
}
